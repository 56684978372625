import { template as template_5c6cdc39d74948ed88685ce8c48f7feb } from "@ember/template-compiler";
const FKControlMenuContainer = template_5c6cdc39d74948ed88685ce8c48f7feb(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
