import { template as template_255ff74cb25e4b9fbdce4ab25f0e0c47 } from "@ember/template-compiler";
const FKLabel = template_255ff74cb25e4b9fbdce4ab25f0e0c47(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
