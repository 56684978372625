import { template as template_4a7f8f533da4467980b619f61c8c5b4b } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_4a7f8f533da4467980b619f61c8c5b4b(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
