import { template as template_a67387ad773643a08d14b4789f0cf531 } from "@ember/template-compiler";
const SidebarSectionMessage = template_a67387ad773643a08d14b4789f0cf531(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
