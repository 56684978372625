import { template as template_0d1cd601f0c14f4aa8e007dade4e9a2f } from "@ember/template-compiler";
const FKText = template_0d1cd601f0c14f4aa8e007dade4e9a2f(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
